exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._446UN {\n  width: 100%;\n  height: 100%;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n}\n.settings-root-subscription {\n  /* background: var(--bg-color) url('/images/Photo_Start.jpg') no-repeat; */\n  /* background-size: cover; */\n}\n._446UN ._3KCGC {\n  position: relative;\n  width: 100%;\n  height: calc(100% - 48px);\n  overflow: auto;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n}\n.UDLs- {\n  width: 100%;\n  height: 100%;\n  top: 0;\n  right: 0;\n  position: fixed;\n  z-index: 1001;\n  background-color: white;\n}\n._278l_ {\n  z-index: 10 !important;\n  width: 50% !important;\n  top: 48px;\n  padding-bottom: 50px;\n}\n.lWoXV {\n  left: 0;\n}\n.bJf0j {\n  -webkit-box-shadow: inset 6px 0px 18px rgba(128, 128, 128, 0.14);\n          box-shadow: inset 6px 0px 18px rgba(128, 128, 128, 0.14);\n}\n.j5tAn {\n  position: absolute;\n  right: 0;\n  cursor: pointer;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n}\n.UDLs- ._1FWJn,\n.UDLs- .LvI6P {\n  color: #999;\n}\n.UDLs- ._1FWJn {\n  margin: 12px 12px 12px 5px;\n}\n.UDLs- .LvI6P {\n  font-size: 13px;\n  font-weight: 500;\n  line-height: 18px;\n}\n._2IbNX {\n  -ms-flex-direction: column;\n      flex-direction: column;\n  -ms-flex-pack: start !important;\n      justify-content: start !important;\n  -ms-flex-align: center !important;\n      align-items: center !important;\n}\n._2IbNX > div:first-child {\n  -ms-flex-positive: 1;\n      flex-grow: 1;\n}\n.n11yF {\n  height: 100% !important;\n}\n\n", ""]);

// Exports
exports.locals = {
	"root": "_446UN",
	"content": "_3KCGC",
	"overlay": "UDLs-",
	"overlay_half": "_278l_",
	"left": "lWoXV",
	"right": "bJf0j",
	"wrapper_close_icon": "j5tAn",
	"close_icon": "_1FWJn",
	"close_icon_text": "LvI6P",
	"with_footer": "_2IbNX",
	"no_header": "n11yF"
};