import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Highlight from '../Monitor/Highlight';
import detailsIframeContainer from '!!raw-loader!./detailsIframeContainer.html';
import pipeFrame from '!!raw-loader!./pipeFrame.html';
import { v4 } from 'uuid';
import Popover from "material-ui/Popover";
import {ContactDetailsTip} from "../ContactBook/ContactDetailsPopover";

type Props = {
  children: string;
  refNo: string,
  search?: RegExp,
};
type State = {};

const EMAIL_REGEXP = /<?([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*) \[[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\]>?/ig;

const Wrapper = styled.div`
  width: 100%;
  overflow: scroll;
  position: relative;
  scroll-padding-top: 75px;
  ${props=> props.shouldShowStickyScroll ? `
  scrollbar-width: none; 
  -ms-overflow-style: none;
  &::-webkit-scrollbar{
    display: none;
  }
  ` : ''}
 blockquote {
   display: block;
   margin: 0 0 0 8px;
   padding-left: 8px;
   border-left:1px solid var(--stroke-light-gray1);
   line-height: 20px;
 }
  iframe {
    min-width: 99%;
    border: none;
  }
  .anchor-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    pointer-events: none;
    scroll-margin-top: 75px;
    .highlight {
      display: inline-block!important;
      scroll-margin-top: 75px;
      pointer-events: none;
    }
  }
`

class MessageContent extends PureComponent<Props, State> {
  iframeId = v4();
  state = {};

  componentDidMount() {
    window.addEventListener('message', this.windowMessageListener);
    this.wrapper = document.getElementById(`iframe-container-${this.iframeId}`);
    window.addEventListener('resize', this.handleScrollVisibility);
    window.addEventListener('scroll', this.handleScrollVisibility)
    this.handleScrollVisibility()
  }

  handleScrollVisibility = () => {
    const {clientWidth, scrollWidth} = this.wrapper;
    if(clientWidth < scrollWidth && this.isStickyScrollInsideWrapper()){
      this.setState({
        clientWidth,
        scrollWidth,
        shouldShowStickyScroll: true,
      })
    } else {
      this.setState({shouldShowStickyScroll: false})
    }
  }

  isStickyScrollInsideWrapper = () => {
    if (!this.wrapper || !this.stickyScroll) return
    const wrapperRect = this.wrapper.getBoundingClientRect();
    const stickyScrollRect = this.stickyScroll.getBoundingClientRect();

    const isWithinBounds =
      stickyScrollRect.top >= wrapperRect.top &&
      stickyScrollRect.bottom <= wrapperRect.bottom; 

    if (isWithinBounds) {
      return true;
    }
    return false
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.windowMessageListener);
    window.removeEventListener('resize', this.handleScrollVisibility);
    window.removeEventListener('scroll', this.handleScrollVisibility);
    clearInterval(this.forceVisibleScrollbarTick);
  }
  shouldComponentUpdate(nextProps, nextState, nextContext): boolean {
    return nextState.contactDetailsPopover !== this.state.contactDetailsPopover || 
    nextState.shouldShowStickyScroll !== this.state.shouldShowStickyScroll ||
    nextState.scrollWidth !== this.state.scrollWidth;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.search !== nextProps.search) {
      this.search(nextProps.search);
    }
  }

  search = (search) => {
    this.anchorContainer.innerHTML = '';

    this.postMessage("search:start", search);
  }

  onSearchCompleted = (matchedNodes) => {
/*    const children = this.anchorContainer.childNodes;
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      this.anchorContainer.removeChild(child);
    }*/
    for (let i = 0; i < matchedNodes.length; i++) {
      const node = matchedNodes[i];
      const span = document.createElement("span");
      span.style = `display:inline-block;position:absolute;left:${node.left}px;top:${node.top}px;width:${node.width}px;height:${node.height}px;background:transparent;`;
      span.setAttribute('data-refno', this.props.refNo);
      span.setAttribute('data-index', i);
      span.classList.add("highlight");
      span.innerHTML = "&nbsp;";
      this.anchorContainer.appendChild(span);
    }
  }

  postMessage = (type, payload) => {
    this.iframeNode.contentWindow.postMessage({ sourceType: 'mail-details', sourceId: this.iframeId, type, payload }, "*");
  }


  windowMessageListener = (e) => {
    const message = e.data;
    if (message?.sourceId !== this.iframeId ) {
      return;
    }
    switch (message.type) {
      case 'frame:loaded':
        this.iframeNode.style = `border: none;height:${message.payload.height}px;width:${message.payload.width}px`;
        this.props.afterRender && this.props.afterRender();
        if (this.props.search) {
          this.search(this.props.search);
        }
        break;
      case 'search:completed':
        this.onSearchCompleted(message.payload);
        break;
      case 'link:click':
        let href = message.payload.href;
        if (window.location.protocol === 'https:') {
          href = href.replace(/^http:/, 'https:');
        }
        const link = document.createElement("a");
        link.href = href;
        link.target = '_blank';
        link.rel = 'noreferrer noopener';
        link.style.position = 'absolute';
        link.style.left = '-9999px';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        break;
      case 'frame:mouseenter':
        this.simulateMoveMove();
        this.forceVisibleScrollbarTick = setInterval(this.simulateMoveMove, 1000);
        break;
      case 'frame:mouseleave':
        clearInterval(this.forceVisibleScrollbarTick);
        break;
      case 'email:mouseenter':
        clearTimeout(this.contactCloseTimer);
        const anchor = document.createElement("span");
        anchor.style = `display:inline-block;position:absolute;left:${message.payload.rect.left}px;top:${message.payload.rect.top}px;width:${message.payload.rect.width}px;height:${message.payload.rect.height}px;background:transparent;`;
        this.anchorContainer.appendChild(anchor);
        this.setState({contactDetailsPopover: <Popover key={message.payload.email} anchorEl={anchor} open onRequestClose={this.closeContactDetails} useLayerForClickAway={false} >
            <ContactDetailsTip
              email={message.payload.email}
              onClose={this.closeContactDetails}
              onMouseOver={this.onContactOver}
              onMouseOut={this.onContactOut}
            />
          </Popover> });
        break;
      case 'email:mouseleave':
          if (this.contactFocused || !this.state.contactDetailsPopover) {
            return;
          }
          this.closeContactDetails();
          break;
      default:
    }
  }

  resizeFrame = () => {
    this.iframeNode.height = this.iframeNode.contentDocument.body.scrollHeight + 50;
    this.iframeNode.width = this.iframeNode.contentDocument.body.scrollWidth + 50;
  }
  onIframeLoaded = () => {
    this.iframeNode.contentWindow.postMessage({ sourceType: 'mail-details', sourceId: this.iframeId, type: 'content', payload: this.props.children }, "*");
  }

  refIframe = (iframe) => {
    this.iframe = iframe;
    this.iframeNode = document.getElementById(this.iframeId);
  }
  refAnchorContainer = (node) => {
    this.anchorContainer = node;
  }

  parseQuoteText = (text) => {
    if(typeof text !== 'string') {
      return text;
    }
    return this.parseQuoteLines(text.split('\n').map(l => l + '\n'));
  }

  parseQuoteLines = (lines) => {
    const parts = [];
    let quoteParts = [];
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      if (line[0] === '>') {
        quoteParts.push(line.substring(1));
      } else {
        if (quoteParts.length) {
          parts.push(<blockquote>{this.parseQuoteLines(quoteParts)}</blockquote>);
          quoteParts = [];
        }
        parts.push(line);
      }
    }
    if (quoteParts.length) {
      parts.push(<blockquote>{this.parseQuoteLines(quoteParts)}</blockquote>);
    }
    return <span>{parts.map(p => this.parseEmails(p))}</span>;

  }

  parseEmails = (text) => {
    if(typeof text !== 'string') {
      return text;
    }
    let previousMatchIndex = 0;
    const parts = [];
    text.replace(EMAIL_REGEXP, (match, p1, offset) => {
      const before = text.substring(previousMatchIndex, offset);
      previousMatchIndex = offset + match.length;
      if (before) {
        parts.push(before);
      }
      parts.push(<a href={`mailto:${p1}`} data-refno={this.props.refNo}>{this.attachHighlight(p1)}</a>);
    });
    const after = text.substring(previousMatchIndex);
    if (after) {
      parts.push(after);
    }
    return <span>{parts.map((t, i) => <span key={i}>{this.attachHighlight(t)}</span>)}</span>;
  }

  attachHighlight = (mayBeText) => {
    if (!this.props.search || typeof mayBeText !== 'string') {
      return mayBeText;
    }
    return <Highlight search={this.props.search} refNo={this.props.refNo}>{mayBeText}</Highlight>;
  }

  simulateMoveMove = () => {
    const clRect = this.wrapper.getBoundingClientRect();
    const evt = new CustomEvent('mousemove', {bubbles: true, cancelable: false});
    evt.clientX = clRect.left;
    evt.clientY = clRect.top;
    this.wrapper.dispatchEvent(evt);
}

  closeContactDetails = () => {
    clearTimeout(this.contactCloseTimer);
    this.contactCloseTimer = setTimeout(() => this.setState({ contactDetailsPopover: null }), 500);
  }
  onContactOver = () => {
    this.contactFocused = true;
    clearTimeout(this.contactCloseTimer);
  }
  onContactOut = () => {
    this.contactFocused = false;
    this.closeContactDetails();
  }

  handleScroll = (e) => {
    this.wrapper.scrollLeft = e.target.scrollLeft;
  }

  handleWrapperScroll = (e) => {
    this.stickyScroll.scrollLeft = e.target.scrollLeft
  }


  render() {
    const htmlString = detailsIframeContainer.replace('{{iframeId}}', this.iframeId);
    const src = 'data:text/html;base64,' + window.btoa(pipeFrame.replace('{{html}}', window.btoa(htmlString)));
    const {shouldShowStickyScroll, scrollWidth, clientWidth} = this.state;
    return (
      <Wrapper shouldShowStickyScroll={shouldShowStickyScroll} onScroll={this.handleWrapperScroll} id={`iframe-container-${this.iframeId}`} >
        <iframe onLoad={this.onIframeLoaded} id={this.iframeId} ref={this.refIframe} src={src} scrolling={'no'} sandbox="allow-scripts allow-same-origin allow-popups allow-top-navigation allow-presentation" />
        <div ref={this.refAnchorContainer} id={`anchor-container-${this.iframeId}`} className={"anchor-container"} />
        {this.state.contactDetailsPopover}
          <div ref={node => this.stickyScroll = node} 
           style={{position: 'fixed',
            bottom: '45px',
            height: '32px',
            background: 'transparent',
            zIndex: 1000,
            paddingLeft: '39px',
            overflowX: 'scroll',
            width: clientWidth || '100%',
            visibility: shouldShowStickyScroll ? 'visible' : 'hidden',
            
            }} onScroll={this.handleScroll}>
            <div className="inner-scroll" style={{width: scrollWidth, height: '1px',}}></div>
          </div>
      </Wrapper>
    );
  }
}

export default MessageContent;
