import React, { useEffect, useState, useRef } from 'react'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import cx from 'classnames'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import Clock from 'material-ui/TimePicker/Clock'
import { Dialog } from '@mui/material';

import styles from './TimePicker.scss'

const customTheme = getMuiTheme({
    timePicker: {
        accentColor: '#285596',
        selectTextColor: '#fff',
    },
});

function getInitialHours(defaultValue, format) {
    if (format === '24hr') return defaultValue.getHours();
    let hoursIn24hrSystem = defaultValue.getHours();
    if (hoursIn24hrSystem === 0) return 12;
    if (hoursIn24hrSystem > 12) return hoursIn24hrSystem - 12;
    return hoursIn24hrSystem;
}


// format: '24hr' | 'ampm'

function TimePickerModal({title, opened, onDismiss, onChange, value = new Date(), onClose, format = 'ampm', minutesStep=5}) {

    const [activeTab, setActiveTab] = useState('hours')
    const [chosenPeriod, setChosenPeriod] = useState(value.getHours() < 12 ? 'am' : 'pm')
    const [hours, setHours] = useState(getInitialHours(value, format));
    const [minutes, setMinutes] = useState(value.getMinutes() || 0);
    const [isClockOpened, setClockOpened] = useState(false);

    const clockRef = useRef();

    useEffect(()=>{
        if(clockRef.current) clockRef.current.setMode(activeTab==='hours' ? 'hour' : 'minute')
        console.log(clockRef?.current);
    },[activeTab])

    useEffect(()=>{
        if (clockRef.current) {
            clockRef.current.setState(prevState => {
                const updatedTime = new Date(prevState.selectedTime.getTime());
                updatedTime.setHours(hours);
                updatedTime.setMinutes(minutes);
                return { selectedTime: updatedTime };
            });
        }
    },[minutes, hours])

    function setValue() {
        const date = getDateValue()
    
        onChange(null, date);
        onClose();
    }
    
    function getDateValue(){
        const date = new Date(value);
        let finalHours = hours;
    
        if (format === 'ampm') {
            if (chosenPeriod === 'am') {
                if (hours === 12) finalHours = 0;
            } else {
                if (hours !== 12) finalHours += 12;
            }
        }
        date.setHours(finalHours);
        date.setMinutes(minutes);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }
    
    function handleChangeMinutes(e){
        let val = parseInt(e.target.value, 10) || 0;
        val = Math.min(59, Math.max(0, val));
        setMinutes(val);
    }

    function handleChangeHours(e) {
        let val = parseInt(e.target.value, 10) || (format === 'ampm' ? 12 : 0);
        if (format === 'ampm') {
            val = Math.min(12, Math.max(1, val));
        } else {
            val = Math.min(23, Math.max(0, val));
        }
        setHours(val);
    }

    function handleClockChangeMinutes(time){
        setMinutes(time.getMinutes())
    }

    function handleClockChangeHours(time){
        setHours(getInitialHours(time,format));
        setActiveTab('minutes')
    }
    
    const initialClockChange = getDateValue();

  return (
    <Dialog
     open={opened}
     sx={{
        '& .MuiPaper-root': {
            borderRadius: '12px',
        }
    }}>
        <div className={styles.content}>
            <div className={styles.title}>{title || 'Select time'}</div>
            <div className={styles.timeInputBlock}>
                <div className={cx(styles.timeInput, activeTab === 'hours' && styles.active)} >
                    <input value={hours.toString().padStart(2, '0')} 
                    onChange={handleChangeHours} 
                    onFocus={(e)=> {
                            e.target.select()
                            setActiveTab('hours')
                        }} />
                    <div className={styles.inputLabel}>Hours</div>
                </div>
                :
                <div className={cx(styles.timeInput,activeTab === 'minutes' && styles.active)}>
                    <input value={minutes.toString().padStart(2, '0')} 
                    onChange={handleChangeMinutes} 
                    onFocus={(e)=> {
                        e.target.select()
                        setActiveTab('minutes')
                    }} />
                    <div className={styles.inputLabel}>Minutes</div>
                </div>
                {format === 'ampm' && (
                    <div className={styles.periodBlock}>
                        <div className={cx(styles.period, chosenPeriod === 'am' && styles.active)} onClick={()=>setChosenPeriod('am')}>am</div>
                        <div className={cx(styles.period, chosenPeriod === 'pm' && styles.active)} onClick={()=>setChosenPeriod('pm')}>pm</div>
                    </div>
                )}
            </div>
            <div className={cx(styles.clockContainer, isClockOpened && styles.open)}>
                <MuiThemeProvider muiTheme={customTheme}>
                    <Clock
                        ref={clockRef}
                        initialTime={initialClockChange}
                        onChangeMinutes={handleClockChangeMinutes}
                        onChangeHours={handleClockChangeHours}
                        format={format}
                        minutesStep={minutesStep}
                        
                    />
                </MuiThemeProvider>
            </div>
            <div className={styles.footer}>
                <div className={styles.icon} onClick={()=>setClockOpened(!isClockOpened)}>
                    {isClockOpened ? <span className='material-icons-outlined'>keyboard</span> : <span className='material-icons-outlined'>schedule</span>}
                </div>
                <div className={styles.buttons}>
                    <button onClick={onDismiss || onClose}>Cancel</button>
                    <button onClick={setValue}>Ok</button>
                </div>
            </div>
        </div>
    </Dialog>
  )
}

export default withStyles(styles)(TimePickerModal)
