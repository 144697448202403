import LinearProgress from 'material-ui/LinearProgress';
//noinspection ES6UnusedImports
import hooks from './hooks.js';
import React, { Children } from 'react';
import { connect } from 'react-redux';
import Dialog from '../components/Common/Dialog';
import Socket from './Socket';
import ShipnextNotificationSystem from './NotificationSystem/ShipnextNotificationSystem';
import { onLoading, offLoading, onError, offError, User } from '../core/api/api';
import { Chat } from './Chat';
import IfLogged from './Common/IfLogged';
import MessageDialog from './Common/MessageDialog';
import ConfirmIntegrator from './Settings/ConfirmIntegrator';
import history from '../core/history';
import { addTopMessage } from '../actions/runtime';
//noinspection ES6UnusedImports
import appCss from './App_.scss';
import Price from '../core/api/Price';
import cx from 'classnames';
import AutoDialogs from './AutoDialogs/AutoDialogs';
import TransitionLocker from './TransitionLocker/TransitionLocker';
import {getTagsCategories} from "../actions/login";
import GlobalComposeEmailDialog from "./Emails/GlobalComposeEmailDialog";
import PropTypes from 'prop-types';
import PubSub from "pubsub-js";
import { injectDictionaries } from "./dictionaries";
import GlobalContactDetailsDialog from "./ContactBook/GlobalContactDetailsDialog";
import IssueReport from './IssueReport/IssueReport';
import { ThemeProvider } from 'styled-components';

const ContextType = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: PropTypes.func.isRequired,
  // Integrate Redux
  // http://redux.js.org/docs/basics/UsageWithReact.html
  store: PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
  }).isRequired,
  muiTheme: PropTypes.object.isRequired,
  countries: PropTypes.array,
  cargoTypes: PropTypes.array,
  contracts: PropTypes.array,
  organizations: PropTypes.array,
  sectors: PropTypes.array,
  industries: PropTypes.array,
  voyageTerms: PropTypes.array,
  timeTerms: PropTypes.array,
  surchargeTypes: PropTypes.array,
  indexedVoyageTerms: PropTypes.array,
  indexedTimeTerms: PropTypes.array,
  ldRates: PropTypes.object,
  containerTypes: PropTypes.array,
  path: PropTypes.string,
  socketUrl: PropTypes.string,
  showMessage: PropTypes.func,
  isPublicPath: PropTypes.bool,
  isUnLockedPathForUnverified: PropTypes.bool,
  isUnLockedPathForUnapproved: PropTypes.bool,
};
/**
 * The top-level React component setting context (global) variables
 * that can be accessed from all the child components.
 *
 * https://facebook.github.io/react/docs/context.html
 *
 * Usage example:
 *
 *   const context = {
 *     history: createBrowserHistory(),
 *     store: createStore(),
 *   };
 *
 *   ReactDOM.render(<App context={context}><HomePage /></App>, container);
 */
class App extends React.Component {
  static propTypes = {
    context: PropTypes.shape(ContextType).isRequired,
    children: PropTypes.element.isRequired,
    hideMessageBar: PropTypes.bool,
  };
  static defaultProps = {
    hideMessageBar: false,
  };
  dictionary = {};
  static childContextTypes = ContextType;

  getChildContext() {
    return this.props.context;
  }

  componentWillMount() {
    let runtime = this.props.context.store.getState().runtime;
    this.props.context.countries = runtime.countries;
    this.props.context.cargoTypes = runtime.cargoTypes;
    this.props.context.contracts = runtime.contracts;
    this.props.context.sectors = runtime.sectors;
    this.props.context.industries = runtime.industries;
    this.props.context.organizations = runtime.organizations;
    this.props.context.ldRates = runtime.ldRates;
    this.props.context.voyageTerms = runtime.voyageTerms;
    this.props.context.timeTerms = runtime.timeTerms;
    this.props.context.indexedVoyageTerms = runtime.voyageTerms.reduce((accum, value) => { accum[value.index] = value; return accum; }, []);
    this.props.context.indexedTimeTerms = runtime.timeTerms.reduce((accum, value) => { accum[value.index] = value; return accum; }, []);
    this.props.context.path = this.props.path;
    this.props.context.isPublicPath = this.props.isPublicPath;
    this.props.context.isUnLockedPathForUnapproved = this.props.isUnLockedPathForUnapproved;
    this.props.context.isUnLockedPathForUnverified = this.props.isUnLockedPathForUnverified;
    this.props.context.surchargeTypes = runtime.surchargeTypes;
    this.props.context.containerTypes = runtime.containerTypes;
    this.props.context.socketUrl = runtime.socketUrl;
    this.props.context.showMessage = params => {
      const defaultMessage = {
        level: "success",
        _id: new Date().getMilliseconds(),
        autoDismiss: 5,
      }
      this.props.context.store.dispatch({
        type: 'MESSAGE',
        payload: {
          ...defaultMessage,
          ...(typeof params === 'object' ? params : {message: params})
        }
      });
    };
    injectDictionaries(runtime);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.props.context.path = nextProps.path;
    this.props.context.isPublicPath = nextProps.isPublicPath;
    this.props.context.isUnLockedPathForUnverified = nextProps.isUnLockedPathForUnverified;
    this.props.context.isUnLockedPathForUnapproved = nextProps.isUnLockedPathForUnapproved;
  }

  async componentDidMount() {
    onLoading(this.handleLoading);
    onError(this.handleApiError);
    try {
      const state = this.props.context.store.getState();
      if (state?.login?.user?._id) {
        if(window.Raven) {
          window.Raven.setUserContext({
            id: state.login.user._id
          })
        }
      }
    } catch (e) {
      console.error(e)
    }

    try {
      const res = await Price.currency();
      if (res.status === 200) {
        this.props.context.store.dispatch({
          type: 'DICTIONARY',
          payload: { currencyRates: res.data.currency.rates },
        });
      }
    } catch (e) {
      console.error(e)
    }
    try {
      const res = await Price.carbonPrice();
      if (res.status === 200) {
        this.props.context.store.dispatch({
          type: 'DICTIONARY',
          payload: { prices: res.data },
        });
      }
    } catch (e) {
      console.error(e);
    }
    window.addEventListener('click', this.linkClickHandler, { capture: true, passive: false });
    this.props.context.store.dispatch(getTagsCategories());
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.linkClickHandler);
    offLoading(this.handleLoading);
    offError(this.handleApiError);
  }

  handleLoading = (counter) => {
    this.props.context.store.dispatch({
      type: 'LOADING',
      payload: counter,
    });
  };

  handleApiError = (e) => {
    if (e && e.status) {
      if (e.status === 401) {
        this.props.context.store.dispatch({
          type: 'LOGOUT',
        });
        history.replace('/login');
      }
      if (e.status === 402) {
        this.props.context.store.dispatch({
          type: 'LOGIN_TOGGLE_SUBSCRIBE_DIALOG',
          payload: true });
      }
    }
  };

  linkClickHandler = (ev) => {
    let target = ev.target;
    while (target.parentNode && target.nodeName !== "A") {
      target = target.parentNode;
    }
    if (target.nodeName === "A") {
      if (target.href && target.href.indexOf('mailto:') === 0) {
        const state = this.props.context.store.getState();
        if (!state.login.mailGatesState?.senderGates?.length || window.location.pathname.indexOf("/emails") === 0) {
          return;
        }
        const position = window.innerWidth / ev.pageX > 2 ? 'right' : 'left';
        PubSub.publish('GlobalComposeEmailDialog', { to: [decodeURIComponent(target.href.slice(7))], position });
        ev.preventDefault();
      }
    }
  }

  render() {
    // NOTE: If you need to add or modify header, footer etc. of the app,
    // please do that inside the Layout component.
    return (
      <span
        name="main"
        className={cx(!this.props.user && 'anonymous')}
      >
        <ThemeProvider defaultProps={{ theme: { name: 'white_theme' }}} theme={{ name: 'white_theme' }}>
          <>
            <TransitionLocker>
            <div key={this.props.children.type.name}>
              {Children.only(this.props.children)}
            </div>
            <GlobalComposeEmailDialog path={this.props.path} />
            <GlobalContactDetailsDialog path={this.props.path} />
            </TransitionLocker>
            {this.props.hideMessageBar ? null : <MessageBar socketUrl={this.props.context.socketUrl} ref="messageBar" path={this.props.path} />}
          </>
        </ThemeProvider>
      </span>
    );
  }
}

class _MessageBar extends React.Component {
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.message && nextProps.message !== this.props.message) {
      this.showMessage({ message: nextProps.message });
      this.props.clearMessage();
    }
  }

  showMessage = ({ message }) => {
    if (typeof message === 'object') {
      this.refs.notificationSystem.addNotification(message);
      return;
    }
    this.refs.notificationSystem.addNotification({
      level: 'success',
      message: message,
    });
  };
  handleRequestClose = () => {
    this.props.clearMessage();
    this.setState({
      message: '',
    });
  };
  state = {};
  render() {
    return (
      <ThemeProvider defaultProps={{ theme: { name: 'white_theme' }}} theme={{ name: 'white_theme' }}>
        <span>
          <ConfirmIntegrator />
          <ShipnextNotificationSystem ref="notificationSystem" noAnimation={true} />
          {this.props.loading
            ? <LinearProgress className="main_progress" mode="indeterminate" />
            : null}
          {this.props.staticPopup
            ? <Dialog
              title={this.props.staticPopup.title}
              autoScrollBodyContent
              handleClose={this.props.clearPopup}
              open
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.staticPopup.content,
                }}
              />
            </Dialog>
            : null}
          <Socket socketUrl={this.props.socketUrl} />
          <IfLogged>
            <IssueReport path={this.props.path} />

          </IfLogged>
          <IfLogged>
            <Chat />

          </IfLogged>
          <MessageDialog />
          <AutoDialogs path={this.props.path} />
        </span>
      </ThemeProvider>
    );
  }
}

const MessageBar = connect(state => state.runtime, {
  clearMessage: function() {
    return { type: 'MESSAGE', payload: '' };
  },
  clearPopup: function() {
    return { type: 'STATIC_POPUP', payload: null };
  },
})(_MessageBar);

export default connect(state => state.login)(App);
